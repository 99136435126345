import React, { Component } from "react"
import { Link } from 'gatsby';
// import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./casestudycapgemini.scss"

import imgQuote from "../images/quotation_mark.webp"

export default ({ pageContext }) => (
    <Layout>
        <SEO
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image}
            link={pageContext.link}
        />
        <div className="case-study-capgemini-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div
                        className="header-container"
                        style={{
                            backgroundImage: `url(${pageContext.acf.header_image.csOneHedImaLocalFile.childImageSharp.fluid.src})`,
                        }}
                    >
                        <img src={imgQuote} alt="" />
                        <h2>{pageContext.acf.header_text}</h2>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-md-8">
                        <img
                            src={
                                pageContext.acf.client_logo.csOneCliLogLocalFile.childImageSharp
                                    .fluid.src
                            }
                            className="client-logo"
                            width={pageContext.acf.client_logo.media_details.width / 2}
                            height={pageContext.acf.client_logo.media_details.height / 2}
                            alt={pageContext.yoast.opengraph_title}
                        />
                    </div>
                    <div className="col-md-4">
                        <a href="https://www.meritmile.com/uploads/2022/11/capgemini-case-study.pdf" className="btn downloadBtn" target="_blank" rel="nofollow noopener noreferrer" style={{ marginTop: '4em'}}>Download the PDF here</a>
                    </div>
                    <div className="col-12">
                        <div className="bar"></div>
                    </div>
                    <div className="col-12">
                        <div
                            className="first-paragraph"
                            dangerouslySetInnerHTML={{
                                __html: pageContext.acf.first_paragraph,
                            }}
                        />
                        {/* <a href="https://www.meritmile.com/uploads/2019/03/QuackSkins-and-Merit-Mile-Case-Study.pdf" className="btn sitebtn" target="_blank" rel="nofollow noopener noreferrer">Read the Full Case Study</a> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div
                            className="second-paragraph"
                            dangerouslySetInnerHTML={{
                                __html: pageContext.acf.second_paragraph,
                            }}
                        />
                    </div>
                    <div className="col-md-6">
                        <img
                            src={pageContext.acf.desktop_image.csOneDesImaLocalFile.childImageSharp.fluid.src}
                            className="desktop-image img-fluid"
                            width={pageContext.acf.desktop_image.media_details.width / 1.5}
                            height={pageContext.acf.desktop_image.media_details.height / 1.5}
                            alt={pageContext.yoast.opengraph_title}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div
                            className="third-paragraph"
                            dangerouslySetInnerHTML={{
                                __html: pageContext.acf.third_paragraph,
                            }}
                        />
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-md-2">
                        <img src="https://www.meritmile.com/uploads/2021/11/techtarget_logo-300x157.png" alt="Tech Target" className="img-fluid" style={{margin: '1.5em auto'}} />
                    </div>
                    <div className="col-md-10">
                        <p>To reach qualified buyers that are actively researching these solutions, Merit Mile partnered with <b>Tech Target</b>, utilizing their <b><em>Highly Qualified Lead</em> (HQL)</b> content marketing offering. The Tech Target HQL program allowed Capgemini to not only reach potential buyers that meet their criteria – title, company size, location, etc. – but more importantly,  reach those doing real-time research on the solution areas with higher levels of buying intent.</p>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-12">
                        <p>To position Capgemini as a trusted and experienced leader and compel a buyer to act, Merit Mile developed high value thought leadership marketing offers. The centerpiece for each solution was a podcast style conversation with subject matter experts from Capgemini and Microsoft, moderated by an independent technology journalist. To elaborate on the topic, industry papers, marketing assets, and digital display content were produced to expand the number of engagement opportunities within the campaign.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3>Results</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <p>Once the campaign content was developed, Merit Mile and Tech Target ran the campaigns over several months throughout 2021. A benefit of working with a trusted demand generation partner like Tech Target is the ability to define guaranteed lead criteria and give our clients the confidence that they will receive the types of leads they are expecting – without question.</p>
                        <p>On schedule, Merit Mile delivered against the campaign lead goals – <b>110 HQL’s for no/low code development and 80 for customer insights</b>. While Merit Mile focused on campaign execution and optimization, Capgemini was able to put their time and energy into ongoing marketing nurture and seller engagement with the leads. As a bonus, <b>Merit Mile tapped into the power of the Tech Target Priority Engine to identify over 200 additional contacts</b> from organizations on Capgemini’s lead lists that were also performing active in-market research with high levels of intent data. </p>
                    </div>
                    <div className="col-md-6">
                        <img
                            src="https://www.meritmile.com/uploads/2021/11/capgemini-results.png"
                            className="results-image img-fluid"
                            alt="Capgemini Case Study Results"
                            width="616px"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="fourth-paragraph">
                            <p>Altogether, this Capgemini content marketing and demand marketing campaign generated nearly 400 new prospect opportunities, and the program yielded a cost per lead (CPL) of ~$50.00 against the budgeted media investments. This CPL in enterprise IT lead generation was not only attractive but also represented the type of efficiency strategy marketers as well as financial officers strive for.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row fifth-paragraph">
                    <div className="col-md-7">
                        <img
                            src="https://www.meritmile.com/uploads/2021/11/deliverables-included.jpg"
                            alt="Deliverables included"
                            className="img-fluid theseIncluded"
                        />
                    </div>
                    <div className="col-md-5">
                        <div className="">
                            <p><b style={{ fontWeight: '600' }}>Deliverables included</b></p>
                            <ul>
                                <li>
                                    Campaign strategy and execution
                                </li>
                                <li>
                                    Subject matter expert podcast
                                </li>
                                <li>
                                    Thought leadership content
                                </li>
                                <li>
                                    Social media and display banners
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="sixth-paragraph">
                            <h3>Quantitative Results</h3>
                            <ul>
                                <li>190 High Quality Leads</li>
                                <li>390 Total Leads</li>
                                <li>200 Priority Contacts</li>
                                <li>$51.28 CPL on Media Spend</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fullWidthImageWrapper">
                <img
                    src="https://www.meritmile.com/uploads/2021/09/mm-divider.jpg"
                    alt="Merit Mile Icon Divider"
                    className="img-fluid"
                />
            </div>
            <div className="about-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h2>About Merit Mile</h2>
                        </div>
                    </div>
                    <div className="row margin-b">
                        <div className="col-md-6">
                            <p>Established in 2007 in Boca Raton, FL, Merit Mile is a marketing services provider focused on helping companies and corporate marketing departments build brands, drive sales revenue, and garner publicity in their respective industries. Our unique differentiator at Merit Mile is our ability to 100% own the message, the publicity, and the demand generation aspects of the corporate marketing function. Clients hire us and rehire us for our ability to refine their messaging/value proposition, develop top and mid-funnel leads, and get the type of editorial publicity that differentiates them from their competitors. From branding to PR to demand generation, Merit Mile is the high-octane fuel that invigorates corporate growth engines. To learn more, please email us at Hello@MeritMile.com, call us at 1-561-362-888, or visit us at www.MeritMile.com. </p>
                        </div>
                        <div className="col-md-6"><img className="img-fluid" src="https://www.meritmile.com/uploads/2021/06/mm-headquater.jpg" alt="Merit Mile Headquarter in Boca Raton, FL" /></div>
                    </div>
                </div>
            </div>
            <div className="container quote-wrapper">
                <div className="row">
                    <div className="col-12">
                        <p>
                            For more insights and to connect<br />
                            with Merit Mile <a href="mailto:mailto:Hello@MeritMile.com?subject=Capgemini%20Case%20Study%20Inquiry"><b style={{ color: "black" }}>email us here</b>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)
